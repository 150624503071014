import React, { useEffect, useState, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { ClipboardContext } from "../context/ClipboardContext";
import { useSnackbar } from "react-simple-snackbar";
import { Helmet } from "react-helmet";

import SectionHeader from "../components/SectionHeader";
import Section from "../components/Section";
import Container from "../components/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Loader from "../components/Loader";
import Card from "../components/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFilter,
	faChevronDown,
	faSearch,
	faXmark,
	faTableCellsLarge,
} from "@fortawesome/free-solid-svg-icons";
import Pagination from "../components/Pagination";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import EmptyState from "../components/EmptyState";
import PageHeaderBg from "../components/PageHeaderBg";

const Cards = () => {
	const [clipboard, setClipboard] = useContext(ClipboardContext);
	const [loading, setLoading] = useState(true);
	const [searching, setSearching] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams({});
	const [cards, fetchCards] = useState([]);
	const [meta, fetchMeta] = useState([]);
	const [types, fetchTypes] = useState([]);
	const [subtypes, fetchSubtypes] = useState([]);
	const [subtypeCols, fetchSubtypeCols] = useState([]);
	const [rarities, fetchRarities] = useState([]);
	const [series, fetchSeries] = useState([]);
	const [showSuggestions, setShowSuggestions] = useState(false);
	const [loadingSuggestions, setLoadingSuggestions] = useState(false);
	const [fromSuggestions, setFromSuggestions] = useState(false);
	const [filterModal, setFilterModal] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const [rawSelected, setRawSelected] = useState(true);
	const [filterCount, setFilterCount] = useState(0);
	const [searchFocused, setSearchFocused] = useState(false);
	const [activeFormat, setActiveFormat] = useState("all");

	const [name, setName] = useState(searchParams.get("q") || "");
	const [rawName, setRawName] = useState("");
	const [searchType, setSearchType] = useState(
		searchParams.get("searchType") || "name"
	);
	const [cardTextFilter, setCardTextFilter] = useState(
		searchParams.get("cardText") || ""
	);
	const [artistFilter, setArtistFilter] = useState(
		searchParams.get("artist") || ""
	);
	const [energyTypeFilter, setEnergyTypeFilter] = useState(
		searchParams.get("energyTypes")
			? searchParams.get("energyTypes").split(",")
			: []
	);
	const [cardTypeFilter, setCardTypeFilter] = useState(
		searchParams.get("cardTypes")
			? searchParams.get("cardTypes").split(",")
			: []
	);
	const [rarityFilter, setRarityFilter] = useState(
		searchParams.get("rarities") ? searchParams.get("rarities").split(",") : []
	);
	const [setFilter, setSetFilter] = useState(
		searchParams.get("sets") ? searchParams.get("sets").split(",") : []
	);
	const [weaknessFilter, setWeaknessFilter] = useState(
		searchParams.get("weaknesses")
			? searchParams.get("weaknesses").split(",")
			: []
	);
	const [resistanceFilter, setResistanceFilter] = useState(
		searchParams.get("resistances")
			? searchParams.get("resistances").split(",")
			: []
	);
	const [formatFilter, setFormatFilter] = useState(
		searchParams.get("format") ? searchParams.get("format") : ""
	);

	const [items, fetchItems] = useState([]);

	const handleSearchSelect = (e, item_name) => {
		e.preventDefault();
		setShowSuggestions(false);
		setRawSelected(true);
		if (item_name) {
			setName(item_name);
			setSearchParams({
				page: 1,
				searchType: searchType,
				q: item_name,
				artist: artistFilter,
				energyTypes: energyTypeFilter.map((value) => value).join(","),
				cardTypes: cardTypeFilter.map((value) => value).join(","),
				rarities: rarityFilter.map((value) => value).join(","),
				sets: setFilter.map((value) => value).join(","),
				weaknesses: weaknessFilter.map((value) => value).join(","),
				resistances: resistanceFilter.map((value) => value).join(","),
				format: formatFilter,
			});
		}
	};

	const toggleSuggestions = (value) => {
		if (value != "") {
			setShowSuggestions(true);
		} else {
			setShowSuggestions(false);
		}
		setName(value);
	};

	const checkSubmit = (e) => {
		if (e.keyCode == 13) {
			setShowSuggestions(false);
			setSearchParams({
				page: 1,
				q: name,
				searchType: searchType,
				artist: artistFilter,
				energyTypes: energyTypeFilter.map((value) => value).join(","),
				cardTypes: cardTypeFilter.map((value) => value).join(","),
				rarities: rarityFilter.map((value) => value).join(","),
				sets: setFilter.map((value) => value).join(","),
				weaknesses: weaknessFilter.map((value) => value).join(","),
				resistances: resistanceFilter.map((value) => value).join(","),
				format: formatFilter,
			});
		}
	};

	const removeFilter = (e, type, func, filterItem, value, key) => {
		e.preventDefault();
		if (type === "list") {
			func(filterItem.filter((prevItem) => prevItem !== value));
		} else {
			func("");
		}
		setSearchParams({
			page: 1,
			q: name,
			searchType: searchType,
			artist: key == "artist" ? value : artistFilter,
			energyTypes:
				key == "energyTypes"
					? filterItem.filter((prevItem) => prevItem !== value).join(",")
					: energyTypeFilter.map((value) => value).join(","),
			cardTypes:
				key == "cardTypes"
					? filterItem.filter((prevItem) => prevItem !== value).join(",")
					: cardTypeFilter.map((value) => value).join(","),
			rarities:
				key == "rarities"
					? filterItem.filter((prevItem) => prevItem !== value).join(",")
					: rarityFilter.map((value) => value).join(","),
			sets:
				key == "sets"
					? filterItem.filter((prevItem) => prevItem !== value).join(",")
					: setFilter.map((value) => value).join(","),
			weaknesses:
				key == "weaknesses"
					? filterItem.filter((prevItem) => prevItem !== value).join(",")
					: weaknessFilter.map((value) => value).join(","),
			resistances:
				key == "resistances"
					? filterItem.filter((prevItem) => prevItem !== value).join(",")
					: resistanceFilter.map((value) => value).join(","),
			format: formatFilter,
		});
	};

	const [defaultSnackbar] = useSnackbar();

	const getData = () => {
		fetch(`${process.env.REACT_APP_API_URI}/cards/${window.location.search}`)
			.then((res) => res.json())
			.then((res) => {
				fetchCards(res.data);
				fetchMeta(res.meta);
				fetchTypes(res.types);
				fetchSubtypes(res.subtypes);
				fetchSubtypeCols(res.subtype_cols);
				fetchRarities(res.rarities);
				// fetchItems(res.card_names);
				fetchSeries(res.series);
				setLoading(false);
				setSearching(false);
				setShowSuggestions(false);
			});
	};

	const getSuggestions = (type) => {
		setLoadingSuggestions(true);
		fetch(
			`${process.env.REACT_APP_API_URI}/cards/suggestions?q=${name}&format=${activeFormat}&type=${type}`
		)
			.then((res) => res.json())
			.then((res) => {
				setSelectedItem(null);
				fetchItems(res.data);
				setLoadingSuggestions(false);
			});
	};

	const nextPage = () => {
		setSearchParams({
			page: searchParams.get("page")
				? parseInt(searchParams.get("page")) + 1
				: 2,
			q: name,
			searchType: searchType,
			artist: artistFilter,
			energyTypes: energyTypeFilter.map((value) => value).join(","),
			cardTypes: cardTypeFilter.map((value) => value).join(","),
			rarities: rarityFilter.map((value) => value).join(","),
			sets: setFilter.map((value) => value).join(","),
			weaknesses: weaknessFilter.map((value) => value).join(","),
			resistances: resistanceFilter.map((value) => value).join(","),
			format: formatFilter,
		});
	};

	const previousPage = () => {
		setSearchParams({
			page: parseInt(searchParams.get("page")) - 1,
			q: name,
			searchType: searchType,
			artist: artistFilter,
			energyTypes: energyTypeFilter.map((value) => value).join(","),
			cardTypes: cardTypeFilter.map((value) => value).join(","),
			rarities: rarityFilter.map((value) => value).join(","),
			sets: setFilter.map((value) => value).join(","),
			weaknesses: weaknessFilter.map((value) => value).join(","),
			resistances: resistanceFilter.map((value) => value).join(","),
			format: formatFilter,
		});
	};

	const handleFilter = (e) => {
		e.preventDefault();
		setSearchParams({
			page: 1,
			q: name,
			searchType: searchType,
			artist: artistFilter,
			energyTypes: energyTypeFilter.map((value) => value).join(","),
			cardTypes: cardTypeFilter.map((value) => value).join(","),
			rarities: rarityFilter.map((value) => value).join(","),
			sets: setFilter.map((value) => value).join(","),
			weaknesses: weaknessFilter.map((value) => value).join(","),
			resistances: resistanceFilter.map((value) => value).join(","),
			format: formatFilter,
		});
		setFilterModal(false);
		setShowSuggestions(false);
	};

	const handleSearchType = (type) => {
		setSearchType(type);
		if (name) {
			setSearchParams({
				page: 1,
				q: name,
				searchType: type,
				artist: artistFilter,
				energyTypes: energyTypeFilter.map((value) => value).join(","),
				cardTypes: cardTypeFilter.map((value) => value).join(","),
				rarities: rarityFilter.map((value) => value).join(","),
				sets: setFilter.map((value) => value).join(","),
				weaknesses: weaknessFilter.map((value) => value).join(","),
				resistances: resistanceFilter.map((value) => value).join(","),
				format: formatFilter,
			});
		}
	};

	const handleFormat = (e, format) => {
		e.preventDefault();
		setFormatFilter(format);
		setSearchParams({
			page: 1,
			q: name,
			searchType: searchType,
			artist: artistFilter,
			energyTypes: energyTypeFilter.map((value) => value).join(","),
			cardTypes: cardTypeFilter.map((value) => value).join(","),
			rarities: rarityFilter.map((value) => value).join(","),
			sets: setFilter.map((value) => value).join(","),
			weaknesses: weaknessFilter.map((value) => value).join(","),
			resistances: resistanceFilter.map((value) => value).join(","),
			format: format,
		});
	};

	const clearFilter = (e) => {
		e.preventDefault();
		setName("");
		setRawName("");
		setArtistFilter("");
		setEnergyTypeFilter([]);
		setCardTypeFilter([]);
		setRarityFilter([]);
		setSetFilter([]);
		setWeaknessFilter([]);
		setResistanceFilter([]);
		setFormatFilter("");

		setSearchParams({});
		setFilterModal(false);
	};

	const highlightSuggestion = (e) => {
		if (e.keyCode === 40) {
			e.preventDefault();
			setFromSuggestions(true);
			if (items.length !== 0) {
				if (selectedItem !== null && selectedItem < items.length - 1) {
					setSelectedItem((prevSelectedItem) => prevSelectedItem + 1);
				} else {
					if (rawSelected) {
						setSelectedItem(0);
						setRawSelected(false);
					} else {
						setSelectedItem(null);
						setRawSelected(true);
					}
				}
			} else {
				setRawSelected(!rawSelected);
			}
		} else if (e.keyCode === 38) {
			e.preventDefault();
			setFromSuggestions(true);
			if (items.length !== 0) {
				if (selectedItem !== null && selectedItem !== 0) {
					setSelectedItem((prevSelectedItem) => prevSelectedItem - 1);
				} else {
					if (selectedItem === 0) {
						setSelectedItem(null);
						setRawSelected(true);
					} else {
						setSelectedItem(items.length - 1);
						setRawSelected(false);
					}
				}
			} else {
				setRawSelected(!rawSelected);
			}
		} else {
			setFromSuggestions(false);
		}
	};

	const toggleFilter = (filterSection, filterToggle) => {
		document.getElementById(filterSection).classList.toggle("active");
		document.getElementById(filterToggle).classList.toggle("active");
	};

	const handleCheck = (event, list, func) => {
		var updatedList = [...list];
		if (event.target.checked) {
			updatedList = [...list, event.target.value];
		} else {
			updatedList.splice(list.indexOf(event.target.value), 1);
		}
		func(updatedList);
	};

	const copyCard = (card, count) => {
		if (clipboard.filter((existing) => existing.id == card.id).length != 0) {
			const newClipboard = [...clipboard];
			newClipboard.filter((existing) => existing.id == card.id)[0].qty += count;
			setClipboard(newClipboard);
		} else {
			setClipboard((clipboard) => [
				...clipboard,
				{
					id: card.id,
					tcg_id: card.tcg_id,
					number: card.number,
					ptcgo_code: (card.card_set && card.card_set.ptcgo_code) || "",
					supertype: card.supertype,
					img: card.lg_img,
					name: card.name,
					qty: count,
				},
			]);
		}
		defaultSnackbar("Added to clipboard.");
	};

	const handleSuggestionHover = () => {
		setRawSelected(false);
		setFromSuggestions(true);
		setSelectedItem(null);
	};

	useEffect(() => {
		if (selectedItem !== null) {
			setName(items[selectedItem].name);
		} else {
			setName(rawName);
		}
	}, [selectedItem]);

	const handleSearchBlur = () => {
		setSearchFocused(false);
		setShowSuggestions(false);
		setLoadingSuggestions(true);
	};

	const handleName = (name) => {
		setName(name);
		setRawName(name);
	};

	useEffect(() => {
		if (!fromSuggestions) {
			if (
				name !== "" &&
				searchFocused &&
				(searchType === "name" || searchType === "abilities")
			) {
				setShowSuggestions(true);
				setLoadingSuggestions(true);
				const timer = setTimeout(() => {
					getSuggestions(searchType);
				}, 500);
				return () => clearTimeout(timer);
			} else {
				setShowSuggestions(false);
				setLoadingSuggestions(true);
			}
		}
	}, [name, activeFormat, searchType]);

	useEffect(() => {
		var fcount =
			energyTypeFilter.length +
			cardTypeFilter.length +
			rarityFilter.length +
			setFilter.length +
			weaknessFilter.length +
			resistanceFilter.length;

		if (cardTextFilter != "") {
			fcount += 1;
		}

		if (artistFilter != "") {
			fcount += 1;
		}
		setFilterCount(fcount);
		setLoadingSuggestions(true);
	}, [searchParams]);

	useEffect(() => {
		setSearching(true);
		getData();
		setName(searchParams.get("q") || "");
		setSearchType(searchParams.get("searchType") || "name");
		setArtistFilter(searchParams.get("artist") || "");
		setEnergyTypeFilter(
			searchParams.get("energyTypes")
				? searchParams.get("energyTypes").split(",")
				: []
		);
		setCardTypeFilter(
			searchParams.get("cardTypes")
				? searchParams.get("cardTypes").split(",")
				: []
		);
		setRarityFilter(
			searchParams.get("rarities")
				? searchParams.get("rarities").split(",")
				: []
		);
		setSetFilter(
			searchParams.get("sets") ? searchParams.get("sets").split(",") : []
		);
		setWeaknessFilter(
			searchParams.get("weaknesses")
				? searchParams.get("weaknesses").split(",")
				: []
		);
		setResistanceFilter(
			searchParams.get("resistances")
				? searchParams.get("resistances").split(",")
				: []
		);
		setFormatFilter(
			searchParams.get("format") ? searchParams.get("format") : ""
		);
		window.scrollTo(0, 0);
	}, [searchParams]);

	return (
		<>
			<Helmet>
				<title>Cards | Pokebuilder</title>
			</Helmet>
			{loading ? (
				<Loader />
			) : (
				<Section className="full-page pb-5">
					<div className="page-header cards flex-row-left">
						<Container>
							<h1 className="page-header-title text-xl white-text bold-text upper mb-0">
								Cards
							</h1>
						</Container>
						<PageHeaderBg className="cards" />
					</div>
					<Container>
						<div className="card-filters main flex-row-left mb-3">
							<div className="card-filters-row col-lg-10 col-md-8 col-12 flex-row-left">
								<div className="search-container card-search">
									<Form onSubmit={handleFilter}>
										<Form.Control
											value={name}
											className="form-control search-text"
											placeholder={`Search card ${searchType}`}
											type="text"
											onChange={(e) => handleName(e.target.value)}
											onKeyDown={highlightSuggestion}
											onFocus={() => setSearchFocused(true)}
											// onBlur={handleSearchBlur}
										/>
										<select
											className="search-toggle bold-text"
											onChange={(e) => handleSearchType(e.target.value)}
											defaultValue={searchType}
										>
											<option value="name">Name</option>
											<option value="abilities">Abilities</option>
											<option value="code">Prefix</option>
											<option value="text">Card Text</option>
										</select>
										<Button
											type="submit"
											className="search-input-icon cards flex-row-right"
										>
											<FontAwesomeIcon icon={faSearch} />
										</Button>
									</Form>
									<div
										className={`suggestions-container background-white ${
											showSuggestions ? "" : "hidden"
										}`}
									>
										{name && (
											<a
												href="#"
												className={`raw-search-result search-suggestion default-text ellipsis ${
													rawSelected && "selected"
												}`}
												onMouseOver={handleSuggestionHover}
												onClick={(e) => handleSearchSelect(e, rawName)}
											>
												<FontAwesomeIcon icon={faSearch} className="me-2" />
												Search for "{rawName}"
											</a>
										)}
										{!loadingSuggestions ? (
											items.map((item, index) => (
												<a
													href="#"
													key={index}
													onMouseOver={handleSuggestionHover}
													onClick={(e) => handleSearchSelect(e, item.name)}
													className={`search-suggestion default-text ellipsis ${
														selectedItem === index ? "selected" : ""
													}`}
												>
													<div className="flex-row-left">
														<div className="suggestion-info">
															<span className="suggestion-name bold-text m-0">
																{item.name}
															</span>
														</div>
													</div>
												</a>
											))
										) : (
											<div className="suggestions-loader flex-column">
												<Spinner
													as="span"
													animation="border"
													size="sm"
													className="default-text"
												/>
											</div>
										)}
									</div>
								</div>
								<div className="format-dropdown-container md-format-dropdown flex-row-left">
									<FontAwesomeIcon
										icon={faTableCellsLarge}
										className="btn-icon white-text"
									/>
									<Form.Select
										className="format-dropdown background-default align-left"
										onChange={(e) => handleFormat(e, e.target.value)}
										value={formatFilter}
									>
										<option value="">All Formats</option>
										<option value="standard">Standard</option>
										<option value="expanded">Expanded</option>
										<option value="theme">Theme</option>
										<option value="unlimited">Legacy</option>
									</Form.Select>
								</div>
								<div className="filter-btn-container flex-row-left">
									<a
										href="#"
										className={`format-picker white-text rounded opacity-5 ${
											formatFilter === "" && "active"
										}`}
										onClick={(e) => handleFormat(e, "")}
									>
										All Formats
									</a>
									<a
										href="#"
										className={`format-picker white-text rounded opacity-5 ${
											formatFilter === "standard" && "active"
										}`}
										onClick={(e) => handleFormat(e, "standard")}
									>
										Standard
									</a>
									<a
										href="#"
										className={`format-picker white-text rounded opacity-5 ${
											formatFilter === "expanded" && "active"
										}`}
										onClick={(e) => handleFormat(e, "expanded")}
									>
										Expanded
									</a>
									<a
										href="#"
										className={`format-picker white-text rounded opacity-5 ${
											formatFilter === "theme" && "active"
										}`}
										onClick={(e) => handleFormat(e, "theme")}
									>
										Theme
									</a>
									<a
										href="#"
										className={`format-picker white-text rounded opacity-5 ${
											formatFilter === "unlimited" && "active"
										}`}
										onClick={(e) => handleFormat(e, "unlimited")}
									>
										Legacy
									</a>
								</div>
								<Modal
									show={filterModal}
									size="lg"
									onHide={() => setFilterModal(false)}
									centered
								>
									<Modal.Header>
										<Modal.Title className="default-text bold-text">
											Filter Cards
										</Modal.Title>
									</Modal.Header>
									<Modal.Body className="pt-4 pb-4">
										<Row>
											<Col>
												<div
													className="filter-toggle flex-row-left"
													onClick={() =>
														toggleFilter("energyTypeFilter", "energyTypeToggle")
													}
												>
													<div className="col-6">
														<label className="default-text bold-text">
															Energy Type ({energyTypeFilter.length})
														</label>
													</div>
													<div className="col-6">
														<FontAwesomeIcon
															icon={faChevronDown}
															id="energyTypeToggle"
															className="filter-toggle-icon default-text pull-right"
														/>
													</div>
												</div>
												<Row
													id="energyTypeFilter"
													className="filter-section mt-1"
												>
													{types.length != 0
														? types.map((type) => (
																<Col
																	key={type.id}
																	className="filter-check col-lg-4 col-sm-6 col-12 flex-row-left"
																>
																	<input
																		id={`type${type.id}`}
																		className="test"
																		value={type.name}
																		type="checkbox"
																		checked={energyTypeFilter.includes(
																			type.name
																		)}
																		onChange={(e) =>
																			handleCheck(
																				e,
																				energyTypeFilter,
																				setEnergyTypeFilter
																			)
																		}
																	/>
																	<img src={type.img} alt="" />
																	<label htmlFor={`type${type.id}`}>
																		{type.name}
																	</label>
																</Col>
														  ))
														: ""}
												</Row>
											</Col>
										</Row>
										<Row>
											<Col>
												<div
													className="filter-toggle flex-row-left"
													onClick={() =>
														toggleFilter("cardTypeFilter", "cardTypeToggle")
													}
												>
													<div className="col-6">
														<label className="default-text bold-text">
															Card Type ({cardTypeFilter.length})
														</label>
													</div>
													<div className="col-6">
														<FontAwesomeIcon
															icon={faChevronDown}
															id="cardTypeToggle"
															className="filter-toggle-icon default-text pull-right"
														/>
													</div>
												</div>
												<Row
													id="cardTypeFilter"
													className="filter-section mt-1"
												>
													{subtypeCols.lenght != 0
														? subtypeCols.map((col) => (
																<Col
																	key={col.name}
																	className="col-lg-3 col-md-6 col-12 mb-3"
																>
																	<SectionHeader className="subtype-header default-text bold-text">
																		{col.name}
																	</SectionHeader>
																	{subtypes.length != 0
																		? subtypes.map((subtype) =>
																				col.subtypes.includes(
																					subtype.name.toLowerCase()
																				) ? (
																					<Col
																						key={subtype.id}
																						className="filter-check col-12 flex-row-left"
																					>
																						<input
																							id={`subtype${subtype.id}`}
																							value={subtype.name}
																							type="checkbox"
																							checked={cardTypeFilter.includes(
																								subtype.name
																							)}
																							onChange={(e) =>
																								handleCheck(
																									e,
																									cardTypeFilter,
																									setCardTypeFilter
																								)
																							}
																						/>
																						<label
																							htmlFor={`subtype${subtype.id}`}
																						>
																							{subtype.name}
																						</label>
																					</Col>
																				) : (
																					""
																				)
																		  )
																		: ""}
																</Col>
														  ))
														: ""}
												</Row>
											</Col>
										</Row>
										<Row>
											<Col>
												<div
													className="filter-toggle flex-row-left"
													onClick={() =>
														toggleFilter("rarityFilter", "rarityToggle")
													}
												>
													<div className="col-6">
														<label className="default-text bold-text">
															Rarity ({rarityFilter.length})
														</label>
													</div>
													<div className="col-6">
														<FontAwesomeIcon
															icon={faChevronDown}
															id="rarityToggle"
															className="filter-toggle-icon default-text pull-right"
														/>
													</div>
												</div>
												<Row id="rarityFilter" className="filter-section mt-1">
													{rarities.length != 0
														? rarities.map((rarity) => (
																<Col
																	key={rarity.id}
																	className="filter-check col-lg-4 col-sm-6 col-12 flex-row-left"
																>
																	<input
																		id={`rarity${rarity.id}`}
																		value={rarity.name}
																		type="checkbox"
																		checked={rarityFilter.includes(rarity.name)}
																		onChange={(e) =>
																			handleCheck(
																				e,
																				rarityFilter,
																				setRarityFilter
																			)
																		}
																	/>
																	<label htmlFor={`rarity${rarity.id}`}>
																		{rarity.name}
																	</label>
																</Col>
														  ))
														: ""}
												</Row>
											</Col>
										</Row>
										<Row>
											<Col>
												<div
													className="filter-toggle flex-row-left"
													onClick={() => toggleFilter("setFilter", "setToggle")}
												>
													<div className="col-6">
														<label className="default-text bold-text">
															Expansions ({setFilter.length})
														</label>
													</div>
													<div className="col-6">
														<FontAwesomeIcon
															icon={faChevronDown}
															id="setToggle"
															className="filter-toggle-icon default-text pull-right"
														/>
													</div>
												</div>
												<Row id="setFilter" className="filter-section mt-1">
													{series.length != 0
														? series.map((cardSeries) => (
																<Row key={cardSeries.id}>
																	<span className="default-text bold-text mt-3 mb-1">
																		{cardSeries.name}
																	</span>
																	{cardSeries.sets.map((set) => (
																		<Col
																			key={set.id}
																			className="filter-check col-lg-4 col-sm-6 col-12 flex-row-left"
																		>
																			<input
																				id={`set${set.id}`}
																				value={set.name}
																				type="checkbox"
																				checked={setFilter.includes(set.name)}
																				onChange={(e) =>
																					handleCheck(
																						e,
																						setFilter,
																						setSetFilter
																					)
																				}
																			/>
																			<label htmlFor={`set${set.id}`}>
																				{set.name}
																			</label>
																		</Col>
																	))}
																</Row>
														  ))
														: ""}
												</Row>
											</Col>
										</Row>
										<Row>
											<Col>
												<div
													className="filter-toggle flex-row-left"
													onClick={() =>
														toggleFilter("weaknessFilter", "weaknessToggle")
													}
												>
													<div className="col-6">
														<label className="default-text bold-text">
															Weaknesses ({weaknessFilter.length})
														</label>
													</div>
													<div className="col-6">
														<FontAwesomeIcon
															icon={faChevronDown}
															id="weaknessToggle"
															className="filter-toggle-icon default-text pull-right"
														/>
													</div>
												</div>
												<Row
													id="weaknessFilter"
													className="filter-section mt-1"
												>
													{types.length != 0
														? types.map((type) => (
																<Col
																	key={type.id}
																	className="filter-check col-lg-4 col-sm-6 col-12 flex-row-left"
																>
																	<input
																		id={`weakness${type.id}`}
																		value={type.name}
																		type="checkbox"
																		checked={weaknessFilter.includes(type.name)}
																		onChange={(e) =>
																			handleCheck(
																				e,
																				weaknessFilter,
																				setWeaknessFilter
																			)
																		}
																	/>
																	<img src={type.img} alt="Card type symbol" />
																	<label htmlFor={`weakness${type.id}`}>
																		{type.name}
																	</label>
																</Col>
														  ))
														: ""}
												</Row>
											</Col>
										</Row>
										<Row>
											<Col>
												<div
													className="filter-toggle flex-row-left"
													onClick={() =>
														toggleFilter("resistanceFilter", "resistanceToggle")
													}
												>
													<div className="col-6">
														<label className="default-text bold-text">
															Resistances ({resistanceFilter.length})
														</label>
													</div>
													<div className="col-6">
														<FontAwesomeIcon
															icon={faChevronDown}
															id="resistanceToggle"
															className="filter-toggle-icon default-text pull-right"
														/>
													</div>
												</div>
												<Row
													id="resistanceFilter"
													className="filter-section mt-1"
												>
													{types.length != 0
														? types.map((type) => (
																<Col
																	key={type.id}
																	className="filter-check col-lg-4 col-sm-6 col-12 flex-row-left"
																>
																	<input
																		id={`resistance${type.id}`}
																		value={type.name}
																		type="checkbox"
																		checked={resistanceFilter.includes(
																			type.name
																		)}
																		onChange={(e) =>
																			handleCheck(
																				e,
																				resistanceFilter,
																				setResistanceFilter
																			)
																		}
																	/>
																	<img src={type.img} alt="Card type symbol" />
																	<label htmlFor={`resistance${type.id}`}>
																		{type.name}
																	</label>
																</Col>
														  ))
														: ""}
												</Row>
											</Col>
										</Row>
										<Row>
											<Col>
												<div
													className="filter-toggle flex-row-left"
													onClick={() =>
														toggleFilter("artistFilter", "artistToggle")
													}
												>
													<div className="col-6">
														<label className="default-text bold-text">
															Artist ({artistFilter == "" ? "0" : "1"})
														</label>
													</div>
													<div className="col-6">
														<FontAwesomeIcon
															icon={faChevronDown}
															id="artistToggle"
															className="filter-toggle-icon default-text pull-right"
														/>
													</div>
												</div>
												<Row id="artistFilter" className="filter-section mt-1">
													<Col className="filter-check no-hover col-lg-4 col-sm-6 col-12 flex-row-left">
														<input
															value={artistFilter}
															className="form-control"
															placeholder="Artist name"
															type="text"
															onChange={(e) => setArtistFilter(e.target.value)}
														/>
													</Col>
												</Row>
											</Col>
										</Row>
									</Modal.Body>
									<Modal.Footer>
										<button
											type="button"
											className="btn btn-default modal-btn"
											onClick={clearFilter}
										>
											Clear
										</button>
										<button
											type="button"
											className="btn btn-primary modal-btn"
											onClick={handleFilter}
										>
											Apply
										</button>
									</Modal.Footer>
								</Modal>
							</div>
							<div className="col-lg-2 col-md-4 col-12">
								<Row className="sm-format-container">
									<Col className="sm-format-dropdown">
										<div className="sm-format-dropdown-container flex-row-left">
											<FontAwesomeIcon
												icon={faTableCellsLarge}
												className="btn-icon white-text"
											/>
											<Form.Select
												className="format-dropdown background-default white-text align-left"
												onChange={(e) => handleFormat(e, e.target.value)}
												value={formatFilter}
											>
												<option value="">All Formats</option>
												<option value="standard">Standard</option>
												<option value="expanded">Expanded</option>
												<option value="theme">Theme</option>
												<option value="unlimited">Legacy</option>
											</Form.Select>
										</div>
									</Col>
									<Col className="flex-row-right">
										<Button
											className="filter-btn"
											onClick={() => setFilterModal(true)}
										>
											<FontAwesomeIcon
												icon={faFilter}
												className="filter-icon white-text"
											/>
											Filter
										</Button>
									</Col>
								</Row>
							</div>
						</div>
						{filterCount !== 0 && (
							<div className="filters-container">
								<span className="filters-label default-text text-lg bold-text">
									Filters:
								</span>
								{artistFilter && (
									<div className="filter-item background-default">
										<span className="white-text">Artist: {artistFilter}</span>
										<a
											href="#"
											className="filter-delete flex-row-right"
											onClick={(e) =>
												removeFilter(
													e,
													"single",
													setArtistFilter,
													artistFilter,
													"",
													"artist"
												)
											}
										>
											<FontAwesomeIcon icon={faXmark} className="white-text" />
										</a>
									</div>
								)}
								{energyTypeFilter.map((filter) => (
									<div key={filter} className="filter-item background-default">
										<span className="white-text">Energy: {filter}</span>
										<a
											href="#"
											className="filter-delete flex-row-right"
											onClick={(e) =>
												removeFilter(
													e,
													"list",
													setEnergyTypeFilter,
													energyTypeFilter,
													filter,
													"energyTypes"
												)
											}
										>
											<FontAwesomeIcon icon={faXmark} className="white-text" />
										</a>
									</div>
								))}
								{cardTypeFilter.map((filter) => (
									<div key={filter} className="filter-item background-default">
										<span className="white-text">Type: {filter}</span>
										<a
											href="#"
											className="filter-delete flex-row-right"
											onClick={(e) =>
												removeFilter(
													e,
													"list",
													setCardTypeFilter,
													cardTypeFilter,
													filter,
													"cardTypes"
												)
											}
										>
											<FontAwesomeIcon icon={faXmark} className="white-text" />
										</a>
									</div>
								))}
								{rarityFilter.map((filter) => (
									<div key={filter} className="filter-item background-default">
										<span className="white-text">Rarity: {filter}</span>
										<a
											href="#"
											className="filter-delete flex-row-right"
											onClick={(e) =>
												removeFilter(
													e,
													"list",
													setRarityFilter,
													rarityFilter,
													filter,
													"rarities"
												)
											}
										>
											<FontAwesomeIcon icon={faXmark} className="white-text" />
										</a>
									</div>
								))}
								{setFilter.map((filter) => (
									<div key={filter} className="filter-item background-default">
										<span className="white-text">Set: {filter}</span>
										<a
											href="#"
											className="filter-delete flex-row-right"
											onClick={(e) =>
												removeFilter(
													e,
													"list",
													setSetFilter,
													setFilter,
													filter,
													"sets"
												)
											}
										>
											<FontAwesomeIcon icon={faXmark} className="white-text" />
										</a>
									</div>
								))}
								{weaknessFilter.map((filter) => (
									<div key={filter} className="filter-item background-default">
										<span className="white-text">Weakness: {filter}</span>
										<a
											href="#"
											className="filter-delete flex-row-right"
											onClick={(e) =>
												removeFilter(
													e,
													"list",
													setWeaknessFilter,
													weaknessFilter,
													filter,
													"weaknesses"
												)
											}
										>
											<FontAwesomeIcon icon={faXmark} className="white-text" />
										</a>
									</div>
								))}
								{resistanceFilter.map((filter) => (
									<div key={filter} className="filter-item background-default">
										<span className="white-text">Resistance: {filter}</span>
										<a
											href="#"
											className="filter-delete flex-row-right"
											onClick={(e) =>
												removeFilter(
													e,
													"list",
													setResistanceFilter,
													resistanceFilter,
													filter,
													"resistances"
												)
											}
										>
											<FontAwesomeIcon icon={faXmark} className="white-text" />
										</a>
									</div>
								))}
								<div className="filter-item background-white">
									<a
										href="#"
										className={`clear-filter-btn default-text bold-text ${
											filterCount === 0 && "invisible"
										}`}
										onClick={clearFilter}
									>
										Clear All
									</a>
								</div>
							</div>
						)}
						{searching ? (
							<Loader className="search-loader" />
						) : cards.length !== 0 ? (
							<>
								<Row>
									{cards.length != 0
										? cards.map((card) => (
												<Card
													key={card.id}
													card={card}
													className="col-lg-3"
													func={copyCard}
													canCopy
												/>
										  ))
										: ""}
								</Row>
								<Pagination
									meta={meta}
									nextPage={nextPage}
									previousPage={previousPage}
									className="flex-row-center mt-5 default-text"
								/>
							</>
						) : (
							<EmptyState className="empty-page" />
						)}
					</Container>
				</Section>
			)}
		</>
	);
};

export default Cards;
